<script>
	//import { page } from '$app/stores'
	import { t, tTag } from '@woltair/translations-package-fe'
	import Thumbnails from '$lib/components/thumbnails/Thumbnails.svelte'

	export let header //content object with heading, subheading, subtext and references_subtext
	export let headerAsterisk = false //adds red asterisk to the end of the heading and the end of the subtext
	export let background = 'bg-neutral-100' //background color of whole header
	export let wrapperCls = '' //additional classes for the main header wrapper
	export let widerText = false //(desktop) make width ratio of the halfs of the header cca 3:2 instead of 1:1 (left:right)
	export let regionName = false //will append region name to the end of the heading if provided
	export let showSubtext = false // bool if to show subtext on the left side
	export let references //content object with references
	export let showReferences = false // bool if to show reference thumbnails on the right side
	export let showRightSlot = false // bool if to show custom content on the right side instead of Thumbnails – defaults to generic illustration if neither slot nor references are true
	export let referencesTitle = regionName
		? `${t('web.components.THUMBNAILS.heading')} ${regionName}`
		: t('web.components.THUMBNAILS.heading')
	const heading = regionName ? `${header?.heading} ${regionName}` : header?.heading
</script>

<div
	class="page-fullwidth {background} h-auto {wrapperCls} {widerText
		? 'pb-12-res lg:pb-0'
		: 'pb-12-res'}"
>
	<div
		class="page-wrapper flex flex-col {widerText
			? 'lg:flex-row lg:gap-10'
			: 'md:flex-row md:gap-10'}"
	>
		<div
			class="flex w-full flex-col md:w-2/3 {widerText ? 'md:min-w-[700px] lg:w-8/12' : 'lg:w-1/2'}"
		>
			<slot name="beforeText" />
			<h1>
				{@html headerAsterisk
					? heading + '<span class="text-primary-500">&nbsp;&ast;</span>'
					: heading}
			</h1>

			<p class="text-xl-res md:text-xl">
				{@html header?.subheading}
			</p>

			<slot name="buttonArea" />

			{#if showSubtext}
				<p class="pt-3 text-sm">
					{#if headerAsterisk}<span class="text-primary-500">*</span>{/if}{@html header?.subtext}
				</p>
			{/if}

			<slot name="afterText" />
		</div>
		<div
			class="flex w-full flex-col"
			class:styleWiderText={widerText}
			class:styleRightSlot={showRightSlot}
			class:styleDefault={!widerText && !showRightSlot}
		>
			{#if showReferences && references?.length}
				<div class="stretch-right-cont relative w-full">
					<h3 class={widerText ? 'lg:max-w-[95%]' : ''}>{@html referencesTitle}</h3>
					<div class="stretch-right-inner">
						<Thumbnails {references} stretchDirection="right" />
					</div>
					<p class="text-lg-res md:text-lg {widerText ? 'lg:max-w-[85%]' : ''}">
						{@html header?.references_subtext}
					</p>
				</div>
			{:else if showRightSlot}
				<slot name="right" />
			{:else}
				<img
					src="/images/illustrations/woltair-house.svg"
					alt={tTag('web.components.header.illustration.imgAlt')}
					width="1566"
					height="969"
				/>
			{/if}
		</div>
	</div>
	<slot name="bottom" />
</div>

<!-- eslint-disable svelte/valid-compile -->
<style lang="scss">
	.styleWiderText {
		@apply lg:mt-24 lg:w-4/12 lg:max-w-[calc(100vw-700px)];
	}
	.styleRightSlot {
		@apply justify-start md:w-1/2;
	}
	.styleDefault {
		@apply justify-center md:w-1/3 lg:w-1/2;
	}
</style>
